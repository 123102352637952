import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const StyledLink = styled(Link)`
  padding: 7px;
  width: 220px;
  border-radius: 5px;
  border: solid 3px transparent;
  margin-bottom: 15px;
  &:hover {
    border-color: #77d7c9;
    text-decoration: underline solid rgba(255, 255, 255, 0);
  }
  .img-wrap {
    width: 200px;
    height: 100px;
    overflow: hidden;
    margin-bottom: 12px;
    img {
      width: 110%;
      height: auto;
    }
  }
  h2 {
    font-size: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 8px;
  }
  span {
    color: #ababab;
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** リンク先 */
  href: string
  /** 画像 */
  imgId: string
  /** タイトル */
  label: string
  /** タイトル */
  date: string
}

/**
 * コラム用カード型ボタンリンクコンポーネント
 * @param props
 * @constructor
 */
const ColumnBtnCard = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <StyledLink className={className} to={props.href}>
      <div className="img-wrap">
        {/** TODO 川上申し送り事項：▼記事ごとに指定されて画像がここに表示されます */}
        <img
          src={`/images/column/details/${props.imgId}`}
          alt="キンクラコラム"
        />
      </div>
      {/** TODO 川上申し送り事項：▼記事のタイトルがここに表示されます */}
      <h2>{props.label}</h2>
      {/** TODO 川上申し送り事項：▼記事の投稿日がここに表示されます */}
      <span>{props.date}</span>
    </StyledLink>
  )
}

export default ColumnBtnCard
