import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0044 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="クラウド型勤怠システムの導入のすすめ" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.02.10</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        労務管理を進めていく中で、最大の懸念点は従業員数が増えるごとに管理が煩雑にならざるを得ないことです。そのための一つの緩和策として、クラウド型勤怠システムの導入が挙げられます。もちろん、使いこなすまでにはある程度の慣れは必要ですが、将来的に従業員数が増えることでいずれアナログ管理には限界が訪れますし、多くの場合、導入後のメリットと、導入しないことによるデメリットは前者の方が大きいと考えます。今回はクラウド型勤怠システムの選び方と導入後のメリットについて解説します。
      </p>
      <img src="/images/column/details/c0044.jpg" alt="キンクラコラム画像44" />

      <h1 id="toc1">クラウド型勤怠システム導入後のメリット</h1>
      <p>
        まずは<span>データ消失のリスク低減</span>
        が挙げられます。クラウド型勤怠システムの場合、言うまでもなくデータの保存がクラウド上で行われます。よって、万が一社内のPCが何らかの理由で起動しなくなったとしてもクラウド上に保存しているためにデータが消失している心配はありません。次に物理的な保管場所の確保が不要ということもメリットです。特に紙での管理では従業員数が増えるごとに物理的な保管番所が必要になります。また、Excelなどのデータ管理であってもシートの数が増えることで、PC内の容量を消費します。しかし、クラウド型勤怠システムの場合は紙管理と異なり、物理的な保管場所が必要となることはなく、繰り返しになりますが、保管番所はクラウド上ですので、直接的にはPCの容量を消費することはありません。
        <br />
        <br />
        もちろんプランにもよりますが、クラウド型勤怠システムは自社製のサーバーを導入するような場合と比較し、
        <span>初期投資も抑える</span>
        ことができます。いくら導入後のメリットを理解できていても、費用的な面は無視することはできません。
        <br />
        <br />
        そして、後述する時間外労働上限規制対応としても威力を発揮します。
        <span>リアルタイムで各従業員の労働時間の推移を把握</span>
        できるため、「働きすぎ」を防ぐことが可能となります。これは管理者だけでなく、自らの健康確保にもつながることから従業員自身にもプラスになると考えます。
        <br />
        <br />
        また、データの管理工数が減ることもメリットです。Excelで管理する場合、一度は計算式を組み立てて、労働時間の算出をする必要があることを筆頭に、ある程度の積み上げ作業を経て、効率的と言えるレベルに到達します。また、最も恐れるべき部分として、万が一データが消失してしまった場合、ゼロから再構築する必要があることも潜在的なリスクと言えます。
        <br />
        <br />
        最後に<span>人為的なミスが減る</span>
        こともメリットです。人の手を介して作業をすること自体、ミスをゼロにすることはできません。それでも労務管理のタスクは労働時間管理一つとっても労働条件の中で最も重要と言っても過言ではない給与に直結するなど、ミスがないことが前提と見られやすい業務領域です。そのために、可能な限りミスが生じえない環境を作り出し、業務を進めていくという発想が重要です。
        <br />
        <br />
        また、2019年4月に「労働安全衛生法」が改正され、
        <span>従業員の労働時間を客観的に把握することが義務化さ</span>
        れました。客観的に把握することとは、以下のような手法を想定しています。
        <br />
        <br />
        ・使用者が現認し、記録する
        ・タイムカード、ICカードといった客観的な記録を基本として確認、記録する
        後付けでの記録になりがちなExcelなどのアナログ型と比べ、パソコンだけなくスマートフォンはじめ様々なデバイスでリアルタイムに打刻できるクラウド型はこの要件との親和性が高いと考えます。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc2">失敗しない選び方</h1>
      <p>
        絶対的な正解が存在しない問題ではありますが、
        <span>直観的に操作しやすいものであるかを念頭に選ぶ</span>
        のがよいと考えます。なぜなら、担当者自身が都度、操作がしづらいという感覚でシステムを使い続けるのは担当者目線でストレスが溜まり、仕事が滞留してしまう（先延ばしにしてしまう）リスクがあるからです。本来、クラウド型勤怠システムを導入する背景には、作業の効率化が挙げられます。そこで、トライアル期間があれば、その期間を活用するのもよいでしょう。
        <br />
        <br />
        また、サポート体制の充実度も重要な基準となります。実際にパッケージの状態と全く同じ会社という方がむしろ稀ですので、自社の実態と合せるため、カスタマイズする際に連絡がつきづらいシステムですと仕事の滞留が発生します。
      </p>

      <h1 id="toc3">どのような企業におすすめか</h1>
      <p>
        これから従業員を増やしていく企業、または増やす予定はないがアナログ管理に限界を感じている企業の2パターンが挙げられます。１点目のこれから従業員を増やしていく企業について、なぜおすすめかと言えば、従業員が増えるということはイコール管理すべき範囲が増えるということを意味します。そもそも従業員を増やそうと決断した背景には業務領域の拡大や現在の従業員数では人手が足りていなかったなどが考えられます。その場合、入社した人材がいくら優秀であっても一時的には長時間労働となってしまうことなども想定しておく必要があります。そうなれば36協定の見直しをはじめ、適切な勤怠管理を行わなければ時間外労働の上限規制に違反してしまうなどのリスクが生じます。特に時間外労働の上限規制は特定の業種を除き、中小企業であっても法律によって既に上限が設定されており、社会的にも厳しい目が向けられています。
      </p>

      <h1 id="toc4">最後に</h1>
      <p>
        昨今、クラウド型勤怠システムは様々な種類がリリースされています。どのシステムもそれぞれ特徴があることから、トライアル期間等を活用し、どのシステムが長期的に自社の労務管理体制に合致しているかを検討しましょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0044
